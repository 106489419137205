@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

/* base styles */
* {
  margin: 0;
  padding:0;
  font-family: "Quicksand";
  color: #333;
}

.navbar {
  margin:0%;
  display: inline-flex;
  min-width: 1350px;
  align-items: center;
}

.navbar .bnt {
  margin-left: 5px;
  margin-right: 10px;
  text-decoration: none;
  max-width: 115px;
  max-height: 80px;
  min-width: 110px;
  font-family: 'Poppins', sans-serif;
  background-color: #f60c00;
  border-color: #ffffffa8;
  color: rgb(255, 255, 255);
  font-size: medium;
}


.App-logo{
  width: 120px;
  height: 120px;
  margin-left: 40px;
  margin-bottom: 5px;
  border-radius: 30%;
}

h1{
  color: #0a0a0a;
  margin: 2%;
  padding: 2%;
  margin-left: 2%;
  font-family: 'Poppins', sans-serif;
  font-size: xx-large;
}

h2{
  color: #070707;
  margin: 2%;
  padding: 2%;
  margin-left: 2%;
  font-family: 'Poppins', sans-serif;
  font-size: x-large;
}

li{
  color: #0a0a0a;
  padding-right: 20%;
  margin-left: 8%;
  font-family: 'Poppins', sans-serif;
  font-size: larger;
}

.lastp{
  color: #080808;
  margin-bottom: 7%;
  font-family: 'Poppins', sans-serif;
}

.copy{
  color: #080808;
  float: center;
  margin-left: 110px;
}

.box{
  margin-left: 250px;
  background-color: #72cc17dc;
  max-width: 750px;
  padding: 30px;
  padding-top:50px;
  padding-left: 90px;
  border-color: #070707;
  border-style: solid;
  border-width: 1px;
  margin-top: -40px;
  margin-bottom: -40px;
}

h3{
  color: #0a0a0a;
  margin: 2%;
  padding: 2%;
  margin-left: 5%;
  font-family: 'Poppins', sans-serif;
  font-size: larger;
}

p{
  color: #0a0a0a;
  padding-right: 20%;
  margin-left: 5%;
  font-family: 'Poppins', sans-serif;
  font-size: larger;
  margin-bottom: 20px;
}

.bg-image {
  background-repeat: no-repeat;
  background-color: #080808;
  background-position: center;
  background-size: 1400px 1500px;
  padding: 40px
}

.con{
  color: #080808;
  margin:0px;
  margin-left: 5%;
}

.conli{
  color: #080808;
  margin-bottom: 30px;
}

li a{
  text-decoration: none;
  color: #0a0a0a;
}

.image1{
  background-image: url('./pic1.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2200px 2200px;
  padding: 40px;
}

.image2{
  background-image: url('./pic2.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1355px 1050px;
  padding: 40px;
}

.image3{
  background-image: url('./pic3.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1355px 1355px;
  padding: 40px;
}

.image4{
  background-image: url('./pic4.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1500px 1000px;
  padding: 40px;
}

.image5{
  background-image: url('./pic5.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1500px 1200px;
  padding: 40px;
}

.image6{
  background-image: url('./pic6.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1400px 1000px;
  padding: 40px;
}